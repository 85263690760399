import JWPlayer from '@jwplayer/jwplayer-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ErrorBoundary from './ErrorBoundary';
import * as rdd from 'react-device-detect';
import uuid from 'react-uuid';
import indicator_icon from "./images/dots-loading.gif";
import { env } from './env';

function randomAlphaNumericStringGenerator() {
  var len = 7;
  var text = "";
  var charset = "ABCDEFGHIkLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < len; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  return text+3;
}

function VideoPlayer(props) {
  let configDefaults = {}
  if(process.env.REACT_APP_COMPANY_ID==9998){
    configDefaults = {  displaytitle: true, responsive: true, aspectratio: "16:9", stretching: "uniform", ga: {}, preload: "none", autostart: "viewable", mute: true };
  }else{
    configDefaults = {  displaytitle: true, responsive: true, aspectratio: "16:9", stretching: "uniform", ga: {}, liveTimeout: 0, autostart: (props.autoplay)?true:false };
  }
  const [nowPlaying, setNowPlaying] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [videoThumb, setVideoThumb] = useState('')
  const [mediaID, setMediaID] = useState('')
  const [playbackUpdated, setPlaybackUpdated] = useState(false)
  const [preRollAdTag, setPreRollAdTag] = useState('')
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [scriptTimeout, setScriptTimeout] = useState(0)

  const advertising =  {
    "client": "vast",
    "adscheduleid": randomAlphaNumericStringGenerator(),
    "schedule": [
      {
        "offset": "pre",
        "tag": preRollAdTag
      }
    ]
  }

  useEffect( () => {
    window.jwp_retries = 0
    let session_identifier = sessionStorage.getItem('session_identifier')
    if (! session_identifier ) {
      sessionStorage.setItem( 'session_identifier' , uuid())
    }
    const script = document.createElement("script");
    document.body.appendChild(script);
    // script.src = "https://showgroundslive.com/js/videoembed/jwplayer8.js?v=20230124-1";
    script.src = "https://cdn.jwplayer.com/libraries/hMVctbog.js";
  } , [] )

  useEffect( () => {
    if ( window.jwplayer ) {

      let sgl_video_visited = sessionStorage.getItem('sgl-video-visited')
      if ( !sgl_video_visited && props.video_asset.wp_asset == 0 ) {
        sessionStorage.setItem( 'sgl-video-visited' , '1')
        // window.location.reload()
      }

      setTimeout(()=>{
        setScriptLoaded(true)
      }, 200)
    } else {
      setTimeout(()=>{
        setScriptTimeout(Date.now())
      }, 1000)
    }
  } , [scriptTimeout] )
  
  useEffect(() => {

    let now_playing_url = ''
    if ( props.video_asset ) {  
      
      if ( rdd.isMobile ) {
        props.video_asset.isMobile = true
      } else {
        props.video_asset.isMobile = false
      }

      if ( props.video_asset.type == 'Uploaded Videos' || props.video_asset.type == 'rvs' ) {

        now_playing_url = props.video_asset.video_url

      } else {

        if ( props.video_asset.event_started == '1' && props.video_asset.event_ended == '0' ) {

          now_playing_url = props.video_asset.video_url
          setPreRollAdTag(props.video_asset.preroll_ad_url);          

        } else if ( props.video_asset.event_started == '1' && props.video_asset.event_ended == '1' && props.video_asset.archive_url != '') {

          now_playing_url = props.video_asset.archive_url

          if ( props.video_asset.play_preroll_on_archives ) {

            setPreRollAdTag(props.video_asset.preroll_ad_url);

          }

        }
        
      }
      
      if ( props.video_asset.wp_asset){
        setVideoTitle("")
      }else{
        setVideoTitle( props.video_asset.title )
      }
      
      if(props.video_asset.placeholder_image!=""){
        setVideoThumb( props.video_asset.placeholder_image )
      }else{
        setVideoThumb( "https://showgroundslive.com/images/placeholder_video.jpg" )
      }
      
      setMediaID( props.video_asset.jwp_media_id )

      if ( now_playing_url ) {
        setNowPlaying( now_playing_url )
      }
    }

  },[props])


   function onJWPSetupError(error) {
    var webservice_url = env.REACT_APP_SGL_API + "/iphone.php/webservice/LogWPPluginError";
    axios.post(webservice_url,{
        datetimestamp: Date.now(),
        customer_id: process.env.REACT_APP_COMPANY_ID,
        from_wp_api: true,
        lineno: '',
        colno: '',
        message: JSON.stringify({code: error.code, message: error.message, type: error.type}),
        user_info: `${localStorage?.getItem('user_display_name')} - ${localStorage?.getItem('user_email')}`,
        session: sessionStorage?.getItem('session_identifier'),
        filename: nowPlaying,
        url: window.location.href,
    })
    
    if ( window.jwp_instance && window.jwp_retries < 3) {
      // await new Promise(r => setTimeout(r, 1000));
      // setTimeout(() => {
      //   window.jwp_retries = 0;
      // },30*1000)
      
      window.jwp_retries += 1
      let configurations = window.jwp_instance.getConfig()
      window.jwp_instance.setup(configurations)
      window.jwp_instance.on('error',(error) => {
        onJWPSetupError(error)
      })
      window.jwp_instance.once('play',(error) => {
        var webservice_url = env.REACT_APP_SGL_API + "/iphone.php/webservice/LogWPPluginError";
        axios.post(webservice_url,{
            datetimestamp: Date.now(),
            customer_id: process.env.REACT_APP_COMPANY_ID,
            from_wp_api: true,
            lineno: '',
            colno: '',
            message: JSON.stringify({code: window.jwp_retries, message: 'played after retry'}),
            user_info: `${localStorage?.getItem('user_display_name')} - ${localStorage?.getItem('user_email')}`,
            session: sessionStorage?.getItem('session_identifier'),
            filename: nowPlaying,
            url: window.location.href,
        })
        window.jwp_retries = 0;
      })
      window.jwp_instance.play()

      axios.post(webservice_url,{
        datetimestamp: Date.now(),
        customer_id: process.env.REACT_APP_COMPANY_ID,
        from_wp_api: true,
        lineno: '',
        colno: '',
        message: JSON.stringify({code: window.jwp_retries, type: 'retried', message: error.message }),
        user_info: `${localStorage?.getItem('user_display_name')} - ${localStorage?.getItem('user_email')}`,
        session: sessionStorage?.getItem('session_identifier'),
        filename: nowPlaying,
        url: window.location.href,
    })

    }
   }
  /**
   * RENDER NOTHING WHEN NO VIDEO FOUND
   */
  if( !props.video_asset || nowPlaying == '' || !scriptLoaded) {
    if ( props.video_asset.wp_asset){
      return (<div style={{'textAlign':'center'}}><img style={{'width':'26px','border':'none'}} src={indicator_icon} /></div>)
    }else{
      return (<div style={{'textAlign':'center'}}>Loading...</div>)
    }
  }
  
  /**
   * RENDER FOR LISTS
   */
  return (
    <>
    <ErrorBoundary no_reload_on_error={props.no_reload_on_error}>
      <JWPlayer
          config={configDefaults}
          // key={process.env.REACT_APP_JWP_KEY}
          library={
            // 'https://showgroundslive.com/js/videoembed/jwplayer8.js?v=20230124-1'
            'https://cdn.jwplayer.com/libraries/hMVctbog.js'
          }
          file={nowPlaying}
          image={videoThumb}
          title={videoTitle}
          mediaid={mediaID}
          advertising={advertising}
          withCredentials={true}
          didMountCallback={(player) => {
            window.jwp_instance = player.player
          }}
          onPlay={() => {

            if( !playbackUpdated ) {
              axios.post( `${env.REACT_APP_API_URL}/video-assets/updatePlayback`, props.video_asset)
              .then( ( video_asset ) => {        
                setPlaybackUpdated(true)
              } );
            }
          }}
          onError={function (error) {
            onJWPSetupError(error)
          }}
      />
      </ErrorBoundary>
    </>
  );
}

export default VideoPlayer;
