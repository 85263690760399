import React from 'react';
// import ReactDOM from 'react-dom/client';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
// window.renderVideo = function(id, category='', delay=1000, theme='', featuredSectionType='', verticalSliderAutoPlay='', verticalSliderNav='', heroAreaBG='', heroAreaFontColor='', carouselItems='', carouselAutoPlay='', carouselSlideBy='', scrollerBG='', scrollerFontColor='') {
//   let root = document.getElementById(id);
//   if ( !root ) {
//     document.write(`<div id='${id}'></div>`)
//     root = document.getElementById(id);
//   }

//   setTimeout(() => {
//     ReactDOM.render(
//       <React.StrictMode>
//         <App embed_id={id} category={category} carouselItems={carouselItems} carouselAutoPlay={carouselAutoPlay} carouselSlideBy={carouselSlideBy} verticalSliderAutoPlay={verticalSliderAutoPlay} verticalSliderNav={verticalSliderNav} featuredSectionType={featuredSectionType} theme={theme} heroAreaBG={heroAreaBG} heroAreaFontColor={heroAreaFontColor} scrollerBG={scrollerBG} scrollerFontColor={scrollerFontColor} />
//       </React.StrictMode>,
//       root
//     );
//   }, delay)
// }


window.onload = function(e){
  var sgl_video_sections = document.querySelectorAll(".sgl-video-section");
  for (let i = 0; i < sgl_video_sections.length; ++i) {

    let id = sgl_video_sections[i].getAttribute("id");
    let dataset = sgl_video_sections[i].dataset;
    dataset = JSON.stringify(dataset);

    let root = document.getElementById(id);
    if ( !root ) {
      document.write(`<div id='${id}'></div>`);
      root = document.getElementById(id);
    }

    ReactDOM.render(
      <BrowserRouter>
        {/* 
        PARAMETERS
        embed_id={id}
        type={livestream,archivestream}
        display_items={3}
        auto_play={false}
        slide_by={1}
        navigation={false}
        featuredSectionType={featuredSectionType}
        theme={theme}
        bg_color={white}
        font_color={black}
        */}
        <App embed_id={id} dataset={dataset} />
      </BrowserRouter>,
      root
    );
  }
}