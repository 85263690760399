import React from "react";
import { useEffect, useState } from "react";
import VideoPlayer from "../VideoPlayer";

export default function ViewStream(props) {
  const [video_asset, setVideoAsset] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [updated, setUpdated] = useState(0);

  useEffect(() => {
    setVideoAsset(props.video_asset);
    setLoaded(true)
  }, [props]);

  return (
    <div className='sgl-column'>
      {
        <div key={video_asset.sgl_id} className='sgl-row'>
          {(video_asset.type == "Uploaded Videos" ||
            video_asset.type == "rvs") &&
            !video_asset.do_login &&
            !video_asset.do_subscription && (
              <div className='sgl-img'>
                <VideoPlayer video_asset={video_asset} no_reload_on_error={true} autoplay={true}/>
              </div>
            )}

          {(video_asset.event_started == "0" ||
            (video_asset.event_ended == "1" && video_asset.archive_url == "") ||
            (video_asset.event_started == "1" && video_asset.video_url == "") ||
            video_asset.do_login ||
            video_asset.do_subscription) &&
            (video_asset.type == "Live Stream" ||
              video_asset.type == "Ring Stream Configuration" ||
              video_asset.type == "rvs") && (
              <div
                className='sgl-img'
                onClick={() => {
                  setUpdated(Date.now());
                }}>
                <img style={{width:'100%'}}
                  src={
                    video_asset.placeholder_image != ""
                      ? video_asset.placeholder_image
                      : "https://showgroundslive.com/images/placeholder_video.jpg"
                  }
                />
              </div>
            )}

          {video_asset.event_started == "1" &&
            video_asset.event_ended == "0" &&
            loaded &&
            (video_asset.type == "Live Stream" ||
              video_asset.type == "Ring Stream Configuration") && (
              <div>
                <VideoPlayer video_asset={video_asset} no_reload_on_error={true} autoplay={true}/>
              </div>
            )}

          {video_asset.event_ended == "1" &&
            loaded &&
            video_asset.archive_url != "" &&
            (video_asset.type == "Live Stream" ||
              video_asset.type == "Ring Stream Configuration") && (
              <div>
                <VideoPlayer video_asset={video_asset} no_reload_on_error={true} autoplay={true}/>
              </div>
            )}
        </div>
      }
    </div>
  );
}
